// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-dettol-src-templates-all-tips-page-all-tips-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/AllTipsPage/AllTipsPage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-all-tips-page-all-tips-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-article-listing-page-article-listing-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/ArticleListingPage/ArticleListingPage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-article-listing-page-article-listing-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-article-page-article-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/ArticlePage/ArticlePage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-article-page-article-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-contact-us-page-contact-us-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/ContactUsPage/ContactUsPage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-contact-us-page-contact-us-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-faq-page-faq-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/FaqPage/FaqPage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-faq-page-faq-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-home-page-home-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-home-page-home-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-lds-page-lds-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/LdsPage/LdsPage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-lds-page-lds-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-product-listing-page-product-listing-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/ProductListingPage/ProductListingPage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-product-listing-page-product-listing-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-product-page-product-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-product-page-product-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-purpose-page-purpose-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/PurposePage/PurposePage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-purpose-page-purpose-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-search-page-search-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/SearchPage/SearchPage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-search-page-search-page-tsx" */),
  "component---gatsby-theme-dettol-src-templates-sitemap-page-sitemap-page-tsx": () => import("./../../../../gatsby-theme-dettol/src/templates/SitemapPage/SitemapPage.tsx" /* webpackChunkName: "component---gatsby-theme-dettol-src-templates-sitemap-page-sitemap-page-tsx" */),
  "component---src-gatsby-theme-dettol-pages-404-tsx": () => import("./../../../src/gatsby-theme-dettol/pages/404.tsx" /* webpackChunkName: "component---src-gatsby-theme-dettol-pages-404-tsx" */)
}

